import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {BsMessenger} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/anil-mandal/" target="_blank" rel="noreferrer" ><BsLinkedin/></a>
        <a href="https://github.com/anil-mandal" target="_blank" rel="noreferrer" ><FaGithub/></a>
        <a href="https://www.facebook.com/anniill4u/" target="_blank" rel="noreferrer" ><BsMessenger/></a>
        
    </div>
  )
}

export default HeaderSocials